import "bootstrap";
import "@fortawesome/fontawesome-free/js/all";
import * as $ from "jquery";

import "select2/dist/css/select2.min.css";
import "select2/dist/js/select2.min.js";

import Swal from "sweetalert2";

import "jquery-ui/themes/base/core.css";
import "jquery-ui/themes/base/theme.css";
import "jquery-ui/themes/base/autocomplete.css";
import "jquery-ui/themes/base/menu.css";
import "jquery-ui/ui/core";
import "jquery-ui/ui/widgets/autocomplete";

import "./app.scss";

$(document).ready(function() {
    /**
     * Alert elastic warning
     */
    display("#message", "none");

    $(".alert-elastic-form").on("submit", function(e) {
        e.preventDefault();
        const button = $(this)
            .children()
            .next();

        const url = button.data("url");

        const spinner =
            "<span class='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span>";

        Swal.fire({
            title: "Are you sure you want to continue?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#2000FF",
            cancelButtonColor: "#FF1D48",
            confirmButtonText: "Continue"
        }).then(
            function(e) {
                if (e.value === true) {
                    $.ajax({
                        method: "POST",
                        url: url,
                        data: {
                            _token: $('meta[name="csrf-token"]').attr("content")
                        },
                        dataType: "JSON",
                        beforeSend: function() {
                            button.html("Updating... " + spinner);
                        },
                        success: function(results) {
                            button.html("Update");
                            $("#message")
                                .attr({
                                    class: "alert alert-success",
                                    role: "alert"
                                })
                                .html(
                                    "<strong>Success!</strong> " +
                                        results.message
                                )
                                .fadeIn()
                                .delay(3000)
                                .fadeOut();
                        },
                        error: function(results) {
                            button.html("Update");
                            const error = results.responseJSON.message;
                            $("#message")
                                .attr({
                                    class: "alert alert-danger",
                                    role: "alert"
                                })
                                .html(error)
                                .fadeIn()
                                .delay(3000)
                                .fadeOut();
                        }
                    });
                } else {
                    e.dismiss;
                }
            },
            function() {
                return false;
            }
        );
    });

    /**
     * Readonly inputs
     */
    $(".readonly").on("keydown paste", function(e) {
        e.preventDefault();
    });

    /**
     * Trace Modal
     */
    $(".traceBtn").on("click", function(e) {
        e.preventDefault();
        const trace = $(this)
            .parent()
            .next()
            .text();

        $("#trace-body").text(trace);
    });

    /**
     * Alert token warning
     */
    $("#alert-token-form").on("submit", function(e) {
        e.preventDefault();
        Swal.fire({
            title: "Are you sure you want to continue?",
            text: "If you create a new token, the old one will be deleted!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#2000FF",
            cancelButtonColor: "#FF1D48",
            confirmButtonText: "Continue"
        }).then(result => {
            if (result.value) {
                $(this)
                    .off("submit")
                    .submit();
            }
        });
    });

    /**
     * Alert edit warning
     */
    $(".alert-confirm-form").on("submit", function(e) {
        e.preventDefault();
        Swal.fire({
            title: "Are you sure you want to continue?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#2000FF",
            cancelButtonColor: "#FF1D48",
            confirmButtonText: "Continue"
        }).then(result => {
            if (result.value) {
                $(this)
                    .off("submit")
                    .submit();
            }
        });
    });

    /**
     * Clear input fields
     */
    function clearFields() {
        $(".is-invalid").map(function(i, el) {
            $(el).removeClass("is-invalid");
        });
    }

    /**
     * Clear display
     */
    function display(element, value) {
        $(element).css("display", value);
    }

    /**
     * Autocomplete university/company search for merge
     */
    function autocompleteMerge(mergeElement, idElement, url) {
        $(mergeElement).autocomplete({
            source: function(request, response) {
                $.ajax({
                    url: url,
                    data: {
                        term: request.term
                    },
                    dataType: "json",
                    beforeSend: function() {
                        $(mergeElement).after(
                            "<div class='text-center mt-2'><div class='spinner-border spinner-border-sm' role='status'><span class='sr-only'>Loading...</span></div></div>"
                        );
                    },
                    success: function(data) {
                        $(mergeElement)
                            .next()
                            .remove();

                        let resp = $.map(data.data, function(obj) {
                            return {
                                value: obj.name,
                                label: obj.name,
                                id: obj.id
                            };
                        });

                        response(resp);
                    }
                });
            },
            minLength: 2,
            delay: 1000,
            select: function(event, ui) {
                $(idElement).attr("value", ui.item.id);
                $(mergeElement)
                    .prev()
                    .children()
                    .html("# " + ui.item.id);
            },
            change: function(event, ui) {
                if (ui.item) {
                    $(this).val(ui.item.label);
                    $(this).removeClass("is-invalid");
                } else {
                    $(this)
                        .next()
                        .remove();
                    $(this).val("");
                    $(this).addClass("is-invalid");
                    $(this).after(
                        "<span class='invalid-feedback' role='alert'><strong>Select something from the drop-down list</strong></span>"
                    );
                }
            }
        });
    }

    autocompleteMerge("#merge_name_u", "#source_id", "/universities/search");
    autocompleteMerge(
        "#with_name_u",
        "#destination_id",
        "/universities/search"
    );

    autocompleteMerge("#merge_name", "#source_id", "/companies/search");
    autocompleteMerge("#with_name", "#destination_id", "/companies/search");

    /**
     * Autocomplete university search
     * Add university id to with input (for university clean)
     */
    display("#no-university", "none");

    $("#universities")
        .autocomplete({
            source: function(request, response) {
                $.ajax({
                    url: "/universities/search",
                    data: {
                        term: request.term
                    },
                    dataType: "json",
                    beforeSend: function() {
                        $("#universities").after(
                            "<div class='text-center mt-2'><div class='spinner-border spinner-border-sm' role='status'><span class='sr-only'>Loading...</span></div></div>"
                        );
                    },
                    success: function(data) {
                        $("#universities")
                            .next()
                            .remove();

                        let resp = $.map(data.data, function(obj) {
                            return {
                                value: obj.name,
                                label: obj.name,
                                id: obj.id
                            };
                        });

                        response(resp);
                    }
                });
            },
            minLength: 2,
            delay: 1000,
            select: function(event, ui) {
                $("#withInput").attr("value", ui.item.label);
                $("#idWithInput").attr("value", ui.item.id);
            },
            response: function(event, ui) {
                if (ui.content.length === 0) {
                    display("#no-university", "block");
                    const universityVal = $("#universities").val();
                    $("#universityBtn").text(universityVal);
                } else {
                    clearFields();
                    $(".clear").val("");
                    display("#no-university", "none");
                }
            }
        })
        .keyup(function() {
            if ($(this).val() == "") {
                clearFields();
                $(".clear").val("");
                display("#no-university", "none");
            }
        });

    /**
     * Add new University if no results
     */
    display("#university-success", "none");

    $("#newUniversityForm").on("submit", function(e) {
        e.preventDefault();

        $.ajax({
            method: "POST",
            url: "/universities/new-university",
            data: {
                name: $("#universities").val(),
                country: $("#country").val(),
                rank: $("#rank").val(),
                _token: $('meta[name="csrf-token"]').attr("content")
            }
        })
            .done(function(response) {
                clearFields();

                $("#withInput").attr("value", response.data.name);
                $("#idWithInput").attr("value", response.data.id);
                display("#no-university", "none");

                $("#university-success")
                    .fadeIn()
                    .delay(2000)
                    .fadeOut();
            })
            .fail(function(error) {
                const errors = error.responseJSON.errors;
                clearFields();

                for (let key in errors) {
                    if (errors.hasOwnProperty(key)) {
                        $(`#${key}`).addClass("is-invalid");
                        $(`#${key}`)
                            .next()
                            .children()
                            .text(errors[key]);
                    }
                }
            });
    });

    /**
     * Add education id to pair input (for university clean)
     */
    $("[data-educationid]").on("click", function(e) {
        e.preventDefault();
        const freeInputName = $(this).text();
        const educationid = $(this).attr("data-educationid");

        $("#pairInput").attr("value", freeInputName);
        $("#idPairInput").attr("value", educationid);
    });

    /**
     * Autocomplete company search
     * Add company id to with input (for company clean)
     */
    display("#no-company", "none");

    $("#companies")
        .autocomplete({
            source: function(request, response) {
                $.ajax({
                    url: "/companies/search",
                    data: {
                        term: request.term
                    },
                    dataType: "json",
                    beforeSend: function() {
                        $("#companies").after(
                            "<div class='text-center mt-2'><div class='spinner-border spinner-border-sm' role='status'><span class='sr-only'>Loading...</span></div></div>"
                        );
                    },
                    success: function(data) {
                        $("#companies")
                            .next()
                            .remove();

                        let resp = $.map(data.data, function(obj) {
                            return {
                                value: obj.name,
                                label: obj.name,
                                id: obj.id
                            };
                        });

                        response(resp);
                    }
                });
            },
            minLength: 2,
            delay: 500,
            select: function(event, ui) {
                $("#withInput").attr("value", ui.item.label);
                $("#idWithInput").attr("value", ui.item.id);
            },
            response: function(event, ui) {
                if (ui.content.length === 0) {
                    display("#no-company", "block");
                    const companyVal = $("#companies").val();
                    $("#newCompanyBtn")
                        .children()
                        .text(companyVal);
                } else {
                    display("#no-company", "none");
                }
            }
        })
        .keyup(function() {
            if ($(this).val() == "") {
                display("#no-company", "none");
            }
        });

    /**
     * Add new Company if no results
     */
    display("#company-success", "none");

    $("#newCompanyBtn").on("click", function(e) {
        e.preventDefault();
        $.ajax({
            method: "POST",
            url: "/companies/new-company",
            data: {
                name: $("#companies").val(),
                _token: $('meta[name="csrf-token"]').attr("content")
            }
        }).done(function(response) {
            $("#withInput").attr("value", response.data.name);
            $("#idWithInput").attr("value", response.data.id);
            display("#no-company", "none");

            $("#company-success")
                .fadeIn()
                .delay(2000)
                .fadeOut();
        });
    });

    /**
     * Add experience id to pair input (for company clean)
     */
    $("[data-experienceid]").on("click", function(e) {
        e.preventDefault();
        const freeInputName = $(this).text();
        const experienceid = $(this).attr("data-experienceid");

        $("#pairInput").attr("value", freeInputName);
        $("#idPairInput").attr("value", experienceid);
    });

    /**
     * Multiselect names for Industries, Sectors and type-sizes
     */
    $(".names").select2({
        allowClear: true,
        placeholder: "Select name"
    });

    /**
     * Multiselect names for User permissions
     */
    $(".permissions").select2({
        allowClear: true,
        placeholder: "Select permission"
    });
});
